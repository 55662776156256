import React from 'react';
import { JSONRPCClient } from "json-rpc-2.0";
import { ethers } from 'ethers';
import './App.css';
import logo from './logo.png';
import COLLECT_HISTORY from './COLLECT_HISTORY.png';
import collectButtonSrc from './BUTTON_GRADIENT.png'
import checkMarkSrc from './CHECKMARK.png'
import viewButtonSrc from './VIEW_BUTTON.png'
import { sequence } from '0xsequence';
import video from './NFT.mp4';
import placeholderVideoImage from './ARENA.jpg'
import { abi_v0, abi_v1 } from './abi'

const POLYGON_CONTRACT_ADDRESS_v0='0x6f91b4DecccA4a78f73C8d21F8F80527c9BB6A5c'
const POLYGON_CONTRACT_ADDRESS_v1='0x3463cB62FB9f5C8122B08F2cDaBcd0300D9dD7F8'

const provider0 = new ethers.providers.JsonRpcProvider('https://nodes.sequence.app/polygon')
const provider1 = new ethers.providers.JsonRpcProvider('https://nodes.sequence.app/polygon')

// const client: any = new JSONRPCClient((jsonRPCRequest: any) =>
//   fetch("http://localhost:4000/json-rpc", {
//     method: "POST",
//     headers: {
//       "content-type": "application/json",
//     },
//     body: JSON.stringify(jsonRPCRequest),
//   }).then((response) => {
//     if (response.status === 200) {
//       return response
//         .json()
//         .then((jsonRPCResponse) => client.receive(jsonRPCResponse));
//     } else if (jsonRPCRequest.id !== undefined) {
//       return Promise.reject(new Error(response.statusText));
//     }
//   })
// );

function App() {
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false)
  const [address, setAddress] = React.useState<any>(null)
  const [status, setStatus] = React.useState<number>(0)
  const [isClaiming, setIsClaiming] = React.useState<boolean>(false)

  sequence.initWallet('polygon') 

  React.useEffect(() => {
    console.log('Version v1.0.0')
  })

  const connect = async () => {

    const wallet = sequence.getWallet()
    const connectWallet = await wallet.connect({
      networkId: 137,
      app: 'Paris Basketball',
      authorize: true,
      settings: {
        theme: 'dark'
      }
    })

    if(connectWallet.connected) {
      setLoggedIn(true)
      setAddress(connectWallet.session!.accountAddress!)
      collect(connectWallet.session!.accountAddress!, connectWallet.proof!.proofString)
    }
  }

  const collect = async (address: string, proof: string) => {
    try{
      const contract0 = new ethers.Contract(POLYGON_CONTRACT_ADDRESS_v0, abi_v0, provider0);
      const contract1 = new ethers.Contract(POLYGON_CONTRACT_ADDRESS_v1, abi_v1, provider1);

      const balance1 = await contract1.balanceOf(address);
      const balance0 = await contract0.balanceOf(address);
      const isMinting = await contract1.minting();

      if(!isMinting){ // check to see if contract is minting
        setStatus(6)
      }else {
        if(Number(balance1) <= 0 && Number(balance0) <= 0) {

          setStatus(3)          

          // client
          // .request("claim", { address: address, proof: proof })
          // .then((result: any) => {
          //     console.log(result)
          //     if(result.status == 5) {
          //       setStatus(result.status)
          //       setStatus(5)
          //     } else if(result.result == 6){
          //       setStatus(6)
          //     } else {
          //       setStatus(1.5)
          //       setTimeout(() => {setStatus(result.status)}, 2000)
          //     }
          // })
          // .catch((err: any) => {
          //     console.log(err)
          //     setStatus(2)
          // })

          fetch("https://parisbasketballcollect.com/collect", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ address: address, proof: proof }),
          })
          .then(async (result: any) => {
            const res = await result.json()
            console.log(res)
            if(res.status == 6) {
              setStatus(res.status)
            }else if(res.status == 5) {
              setStatus(res.status)
            }else if (res.status == 2) {
              setStatus(res.status)
            } else if(res.status == 4){
              setStatus(res.status)
            } else {
              setStatus(1.5)
              setTimeout(() => {setStatus(res.status)}, 2000)
            }
          }).catch((err: any) => {
            console.log(err)
            setStatus(2)
          })

        } else {
          setStatus(4)
        }
      }
    }catch(e){
      console.log(e)
      setStatus(2)
    }
  }

  const openWallet = () => {
    const wallet = sequence.getWallet()
    wallet.openWallet()
  }

  return (
    <div className="App">
      <img className='logo' src={logo} />
      {
        status == 0 ?
          <>
            <div className="video-container">
              <video src={video} controls poster={placeholderVideoImage}></video>
            </div>
            <div className="vertical-line"></div>
            <div className='container'>
              <img className='collect'  onClick={connect} src={collectButtonSrc}/>
              <span className='button-text' onClick={connect}>COLLECT</span>
            </div>
          </>
        :
        status == 1.5 ?
          <>
            <div className="video-container">
              <video src={video} controls poster={placeholderVideoImage}></video>
            </div>
            <h1 className='claiming-loading'>CLAIMED.</h1>
            <img src={checkMarkSrc} />
          </>
        :
        status == 3 ?
          <>
            <div className="video-container">
              <video src={video} controls poster={placeholderVideoImage}></video>
            </div>
            <h1 className='claiming-loading'>CLAIMING...</h1>
          </>
        :
        status == 4 ?
          <>
            <div className="video-container">
              <video src={video} controls></video>
            </div>
            <div className="vertical-line"></div>
            <div className='container'>
              <img className='view' src={collectButtonSrc} onClick={openWallet}/>
              <span className='button-text' onClick={openWallet}>OPEN WALLET</span>
            </div>
            <h1 className='support'>You've already claimed this NFT <br/> Thank you for your support.</h1>
          </>
        :
        status == 1 ?
          <>
            <div className="video-container">
              <video src={video} controls poster={placeholderVideoImage}></video>
            </div>
            <div className="vertical-line"></div>
            <div className='container'>
              <img className='view' src={collectButtonSrc} onClick={openWallet}/>
              <span className='button-text' onClick={openWallet}>OPEN WALLET</span>
            </div>
            <p className='support'>See you in September.</p>
          </>
        :
        status == 6 ?
          <>
            <div className="video-container">
              <video src={video} controls poster={placeholderVideoImage}></video>
            </div>
            <div className="vertical-line"></div>
            <div className='container'>
              <img className='view' src={collectButtonSrc} onClick={openWallet}/>
              <span className='button-text' onClick={openWallet}>OPEN WALLET</span>
            </div>
            <h1 className='support'>We've closed minting for this collectible.<br/>See you in September.</h1>
          </>
        :
          <>
            <br/>
            <br/>
            <br/>
            <p className='support'>We apologize there has been an error. <br/>Check back in some time.</p>
          </>
      }
    </div>
  );
}

export default App;